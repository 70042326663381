import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { navigate, Link } from "gatsby"

import { LocalStorageKeys } from "context/AuthContext"
import jiggswapApi from "api/jiggswap"
import RequireAuth from "../../components/auth/RequireAuth"
import AddressModal from "../../components/profiles/AddressModal"
import { MapApiErrors } from "../../helpers/ErrorParser"

const PuzzleDisplay = ({ puzzle }) => {
  if (!puzzle) {
    return <>Not Selected</>
  }

  return (
    <div>
      <div className="has-text-centered">
        <figure className="image is-4by3" style={{ border: "1px solid #aaa" }}>
          <img src={puzzle.imageCdnUrl} alt={puzzle.title} />
        </figure>
      </div>
      <div className="has-text-centered">
        <div>
          <span className="has-text-weight-medium m-2">Title:</span>
          <span>{puzzle.title}</span>
        </div>
        <div>
          <span className="has-text-weight-medium m-2">Brand:</span>
          <span>{puzzle.brand}</span>
        </div>
        <div>
          <span className="has-text-weight-medium m-2"># Pieces:</span>
          <span>
            {puzzle.numPieces} (<span className="has-text-weight-medium">{puzzle.numPiecesMissing}</span> missing)
          </span>
        </div>
      </div>
    </div>
  )
}

const PuzzleSelectItem = ({ puzzle, onSelect }) => (
  <div>
    <div className="columns is-mobile is-multiline is-centered">
      <div className="column is-three-quarters-mobile has-text-centered">{puzzle.title}</div>
      <div className="column is-three-quarters-mobile has-text-centered">
        <figure className="image is-96x96" style={{ margin: "0 auto" }}>
          <img src={puzzle.imageCdnUrl} alt={puzzle.title} />
        </figure>
      </div>

      <div className="column is-three-quarters-mobile has-text-centered">
        <button className="button is-primary" onClick={() => onSelect(puzzle.puzzleId)}>
          Select
        </button>
      </div>
    </div>
    <hr />
  </div>
)

const PuzzleSelect = ({ puzzles, onSelect, selectedId }) => {
  return (
    <div style={{ border: "1px solid #aaa", borderRadius: "5px", paddingTop: "10px" }}>
      {puzzles.map((p) => (
        <PuzzleSelectItem key={p.puzzleId} puzzle={p} onSelect={onSelect} />
      ))}
    </div>
  )
}

const TradeStartPage = ({ location }) => {
  const [myUsername, setMyUsername] = useState("")
  const [myId, setMyId] = useState(null)
  const [myPuzzlesList, setMyPuzzlesList] = useState([])
  const [mySelectedPuzzle, setMySelectedPuzzle] = useState(null)

  const [buddyUsername, setBuddyUsername] = useState("")
  const [buddyId, setBuddyId] = useState("")
  const [buddyPuzzlesList, setBuddyPuzzlesList] = useState([])
  const [buddySelectedPuzzle, setBuddySelectedPuzzle] = useState(null)

  const [needAddress, setNeedAddress] = useState(null)
  const [apiErrors, setApiErrors] = useState([])

  useEffect(() => {
    if (needAddress === false) {
      submitTrade()
    }

    // eslint-disable-next-line
  }, [needAddress])

  useEffect(() => {
    setMyUsername(localStorage.getItem(LocalStorageKeys.username))

    const urlParams = new URLSearchParams(window.location.search)
    setBuddyUsername(urlParams.get("user"))
    setBuddyId(urlParams.get("p"))
  }, [])

  useEffect(() => {
    if (myUsername && buddyUsername) {
      const fetchData = async () => {
        const myPuzzlesReq = jiggswapApi.get(`/puzzles?owner=${myUsername}`)
        const buddyPuzzlesReq = jiggswapApi.get(`/puzzles?owner=${buddyUsername}`)

        const myPuzzles = await myPuzzlesReq
        const buddyPuzzles = await buddyPuzzlesReq

        setMyPuzzlesList(myPuzzles.data)
        setBuddyPuzzlesList(buddyPuzzles.data)
      }

      fetchData()
    }
  }, [myUsername, buddyUsername])

  useEffect(() => {
    setMySelectedPuzzle(myPuzzlesList.find((p) => p.puzzleId === myId))
  }, [myId, myPuzzlesList])

  useEffect(() => {
    setBuddySelectedPuzzle(buddyPuzzlesList.find((p) => p.puzzleId === buddyId))
  }, [buddyId, buddyPuzzlesList])

  const submitTrade = async () => {
    try {
      await jiggswapApi.post("/trades", {
        initiatorPuzzleId: myId,
        requestedPuzzleId: buddyId,
      })

      if (typeof window !== `undefined`) {
        navigate("/trades")
      }
    } catch (err) {
      if (err.response.data.errors["currentUser.Address"]) {
        setNeedAddress(true)
      } else {
        const errs = MapApiErrors(err.response.data.errors).map((e) => e.message)
        setApiErrors(errs)
      }
    }
  }

  return (
    <>
      <Helmet title={"Trade With " + buddyUsername + " - JiggSwap"} />
      <RequireAuth />
      {needAddress && <AddressModal active={needAddress} setActive={setNeedAddress} />}
      <div className="is-size-2 has-text-centered">Starting a Trade with {buddyUsername}</div>
      <hr />
      <div className="columns is-mobile is-centered is-multiline">
        <div className="column is-half-tablet is-three-quarters-mobile" style={{ padding: "15px" }}>
          {mySelectedPuzzle && (
            <>
              <div className="has-text-centered is-size-4">My Selected Puzzle:</div>
              <br />
              <PuzzleDisplay puzzle={mySelectedPuzzle} />

              <div className="has-text-centered" style={{ marginTop: "25px" }}>
                <button type="button" className="button is-secondary" onClick={setMyId}>
                  Select Different Puzzle
                </button>
              </div>
            </>
          )}
          {!mySelectedPuzzle && (
            <>
              <div className="has-text-centered is-size-4">Select one of your puzzles to trade:</div>
              <br />
              {myPuzzlesList.length > 0 && (
                <>
                  <PuzzleSelect puzzles={myPuzzlesList} onSelect={setMyId} selectedId={myId} />
                </>
              )}
              {myPuzzlesList.length === 0 && (
                <>
                  <div className="has-text-centered">
                    <div className="has-text-centered">It looks like you don't have any puzzles yet.</div>
                    <Link to="/puzzles/add" className="button is-primary">
                      Add a puzzle
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="column is-half-tablet is-three-quarters-mobile" style={{ padding: "15px" }}>
          {buddySelectedPuzzle && (
            <>
              <div className="has-text-centered is-size-4">{buddyUsername}'s Puzzle:</div>
              <br />
              <PuzzleDisplay puzzle={buddySelectedPuzzle} />

              <div className="has-text-centered" style={{ marginTop: "25px" }}>
                <button type="button" className="button is-secondary" onClick={setBuddyId}>
                  Select Different Puzzle
                </button>
              </div>
            </>
          )}
          {!buddySelectedPuzzle && (
            <>
              <div className="has-text-centered is-size-4">Select one of {buddyUsername}'s puzzles you want to trade for:</div>
              <br />
              <PuzzleSelect puzzles={buddyPuzzlesList} onSelect={setBuddyId} selectedId={buddyId} />
            </>
          )}
        </div>
      </div>
      <div className="has-text-centered">
        <div className="has-text-danger">
          {apiErrors.map((a) => (
            <div key={a}>{a}</div>
          ))}
          <div>{!buddySelectedPuzzle && <>Please choose a puzzle for {buddyUsername}.</>}</div>
          <div>{!mySelectedPuzzle && <>Please choose one of your puzzles.</>}</div>
        </div>
        {mySelectedPuzzle && buddySelectedPuzzle && (
          <>
            <div className="button is-large is-primary" onClick={submitTrade} onKeyPress={submitTrade} role="button" tabIndex={0}>
              Start Trade!
            </div>
          </>
        )}
        {(!mySelectedPuzzle || !buddySelectedPuzzle) && (
          <>
            <div className="button is-large is-primary is-disabled" disabled>
              Start Trade!
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default TradeStartPage
